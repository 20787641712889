@import url(https://use.fontawesome.com/releases/v6.1.0/css/all.css);
/* Affect the mantine dropdown only */
body > div:not(#root) * {
    transition: none;
}

a[href="/login"] {
    color: white;
}
  
a[href="/logout"] {
    color: white;
}

a {
    color: #E83D84;
}

body {
    background-color: #eee;

}

@media screen and (max-width: 600px) {
    body {
        font-size: 14px;
    }
}

input[type="number"] {
    border: none;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1);
    color: #696969;
    padding: 10px;
    border: 1px solid #f7f7f7;
    height: 50px;
}

input[type="number"]:disabled {
    cursor: not-allowed;
}

div.rdg-checkbox, div.rdg-row, div.rdg-cell {
    transition: none;
}

